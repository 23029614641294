define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/utilities", "discourse-common/helpers/d-icon", "discourse-common/lib/later", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _service, _dButton, _utilities, _dIcon, _later, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ActivityPubHandle;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ActivityPubHandle = _exports.default = (_class = (_ActivityPubHandle = class ActivityPubHandle extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "copied", _descriptor3, this);
    }
    copy() {
      (0, _utilities.clipboardCopy)(this.args.actor.handle);
      this.copied = true;
      (0, _later.default)(() => {
        this.copied = false;
      }, 2000);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="activity-pub-handle">
        <div class="activity-pub-handle-contents">
          <span class="handle">{{@actor.handle}}</span>
          {{#if @actor.url}}
            <a
              href={{@actor.url}}
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-icon no-text"
            >{{icon "external-link-alt"}}</a>
          {{/if}}
          {{#if this.copied}}
            <DButton @icon="copy" @label="ip_lookup.copied" class="btn-hover" />
          {{else}}
            <DButton @action={{this.copy}} @icon="copy" class="no-text" />
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "yVVETTR9",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-handle\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-handle-contents\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"handle\"],[12],[1,[30,1,[\"handle\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"url\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"btn btn-icon no-text\"],[12],[1,[28,[32,0],[\"external-link-alt\"],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"copied\"]],[[[1,\"          \"],[8,[32,1],[[24,0,\"btn-hover\"]],[[\"@icon\",\"@label\"],[\"copy\",\"ip_lookup.copied\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],[[24,0,\"no-text\"]],[[\"@action\",\"@icon\"],[[30,0,[\"copy\"]],\"copy\"]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@actor\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle.js",
    "scope": () => [_dIcon.default, _dButton.default],
    "isStrictMode": true
  }), _ActivityPubHandle), _ActivityPubHandle), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "copied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "copy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copy"), _class.prototype)), _class);
});