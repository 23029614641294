define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-actor-follows", ["exports", "@ember/array", "discourse/routes/discourse", "discourse-common/utils/decorators", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _array, _discourse, _decorators, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    },
    afterModel(_, transition) {
      const actor = this.modelFor("activityPub.actor");
      if (!actor.can_admin) {
        this.router.replaceWith("/404");
        return;
      }
      return _activityPubActor.default.list(actor.id, transition.to.queryParams, "follows").then(response => this.setProperties(response));
    },
    setupController(controller) {
      controller.setProperties({
        actor: this.modelFor("activityPub.actor"),
        actors: (0, _array.A)(this.actors || []),
        loadMoreUrl: this.meta?.load_more_url,
        total: this.meta?.total
      });
    },
    activate() {
      this.messageBus.subscribe("/activity-pub", this.handleMessage);
    },
    deactivate() {
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    },
    handleMessage(data) {
      const model = data.model;
      const actor = this.modelFor("activityPub.actor");
      if (model && model.type === "category" && model.id === actor.id) {
        this.refresh();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "handleMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "handleMessage"), _obj)), _obj));
});